import React, { createContext, useState, useContext } from "react";
// import ActionAllowed from "constants/ActionAllowed";
import {
  AuthToken,
  AuthUser,
  SelectedProduct,
} from "api_services/localstorage";
import { AUTH_TOKENS } from "constants/Constants";

/**
 * Provider for user context which holds user value
 */
const AuthContext = createContext();

/**
 * Instead of importing context using useContext
 * everytime inside components, this function allows
 * to directly import the context without repeating
 * the below chunk of code.
 *
 * What it solves is the problem of not using useContext
 * everywhere for eg:
 *
 *  const context = useContext(AuthContext); Not Recommended
 *
 * Better code
 *  import { context } from 'AuthContext';
 *
 */
const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useCountState must be used within a CountProvider");
  }
  return context;
};

export { useAuthContext, AuthContext };

/**
 * @description - Auth context provide component which sets all
 * the user related value and provides methods to get them in
 * oother components
 * @param {*} props
 */
const AuthContextProvider = (props) => {
  const [isAuthenticated, setAuthenticated] = useState(
    !!AuthToken.getToken(AUTH_TOKENS.ACCESS_TOKEN)
  );
  // let initUserData = {
  //   role: "",
  // };
  if (AuthToken.getToken(AUTH_TOKENS.ACCESS_TOKEN) && AuthUser.getUser()) {
    // initUserData = AuthUser.getUser();
  }
  // const [userRole, setUserRoles] = useState(initUserData['cognito:groups']);
  const [user, setUser] = useState(AuthUser.getUser());

  const signout = () => {
    AuthToken.removeToken();
    AuthUser.removeUser();
    SelectedProduct.removeProduct();
    setAuthenticated(false);
    // setUserRoles("");
    setUser({});
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        // userRole,
        user,
        // setUserRoles,
        setAuthenticated,
        setUser,
        signout,
        // ActionAllowed,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
