import React from "react";
import { Typography, Button } from "antd";
import { MCFoooter } from "assets";
import { useHistory, useLocation } from "react-router-dom";
import style from "./_footer.module.scss";

const { Text, Link } = Typography;

const Footer = () => {
  const history = useHistory();
  const location = useLocation();

  const userGuide = () => {
    history.push("/user-guide");
  };

  return (
    <footer className={style.footerContainer}>
      <div className={style.linkSection}>
        {!!location.pathname[1] && location.pathname !== "/setting" && (
          <>
            <Link className={style.footerLink} href="/overview">
              Terms of Use
            </Link>
            <Link className={style.footerLink} href="/overview">
              Privacy Policy
            </Link>
          </>
        )}
        <Link
          className={style.footerLink}
          href="https://mail.google.com/mail/?view=cm&fs=1&to=support@machinecover.com"
          target="_blank"
        >
          Contact Us
        </Link>
      </div>
      <div className={style.spacer} />
      {/* <Button
        className={style.userGuide}
        type="link"
        size="small"
        onClick={userGuide}
      >
        User Guide
      </Button> */}
      <div className={style.copyrightSection}>
        <Text type="secondary" className={style.copyrightText}>
          Powered by
        </Text>
        <img src={MCFoooter} alt="Header logo" />
      </div>
    </footer>
  );
};

export default Footer;
