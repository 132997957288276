import React from "react";
import { Typography } from "antd";
import style from "./overview.module.scss";

const { Text } = Typography;

const LoanProtectOverview = () => (
  <>
    <div className={style.overviewTitleSection}>
      <Text className={style.title}>Underwriting LoanProtect - Overview</Text>
    </div>
    <div className={style.overviewText}>
      <ul>
        <li>
          The engine is built upon roughly 450 million data points covering
          several years of financial transactions across the USA. Those data
          points in turn depend upon the aggregation of tens of billions of
          individual transactions during this period.
        </li>
        <li>
          It operates upon roughly 22,000 squares of approximately 1.5 sq, miles
          each, which Machine Cover deems LoanProtect-worthy, i.e. any suitable
          interested party located in these squares may buy an a LoanProtect.
        </li>
        <li>
          LoanProtect protects the borrower of a commercial loan against a drop
          in the economic activity of the neighborhood in which they operate -
          which may make it harder for the borrower to make payments on the
          loan.
        </li>
        <li>
          If a policyholder buys a LoanProtect, their LoanProtect will trigger
          (i.e. they are owed a predetermined amount of money) if the square
          they happen to belong to, experiences a drop in economic activity that
          is worse than the nation as a whole, the drop is not predictable and
          economic activity continues in its post-drop state for a predetermined
          period of time.
        </li>
        <li>
          This Underwriting Engine explores what the probability of such a
          trigger is, depending on what you set on the next page.
        </li>
      </ul>
    </div>
  </>
);

export default LoanProtectOverview;
