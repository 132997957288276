import React, { useEffect, useRef } from "react";
import queryString from "query-string";
import PropTypes from "prop-types";
import { AuthToken, AuthUser } from "api_services/localstorage";
import { Spin } from "antd";
import { useAuthContext } from "contexts/AuthContext";
import axios from "axios";
import jwt from "jsonwebtoken";
import { AUTH_TOKENS } from "constants/Constants";
import style from "./signinCallback.module.scss";

const SigninCallback = (props) => {
  const { history, location } = props;
  const locationSearch = location.search;
  const userContext = useRef(useAuthContext());

  useEffect(() => {
    const authCode = queryString.parse(locationSearch);
    if (authCode && authCode.code) {
      const params = new URLSearchParams();
      params.append("grant_type", "authorization_code");
      params.append("code", authCode.code);
      params.append("client_id", process.env.REACT_APP_AWS_COGNITO_CLIENT_ID);
      params.append("redirect_uri", process.env.REACT_APP_AWS_REDIRECT_URI);
      axios
        .post(`${process.env.REACT_APP_AWS_DOMAIN}/oauth2/token`, params, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then((res) => {
          if (res && res.data) {
            const userData = jwt.decode(res.data.id_token);
            AuthToken.setToken(AUTH_TOKENS.ACCESS_TOKEN, res.data.access_token);
            AuthToken.setToken(
              AUTH_TOKENS.REFRESH_TOKEN,
              res.data.refresh_token
            );
            userContext.current.setAuthenticated(true);
            userContext.current.setUser(userData);
            AuthUser.setUser(userData);
            history.push("/");
          }
        })
        .catch((err) => console.log("Error", err));
    }
  }, [locationSearch, history]);

  return (
    <Spin spinning style={{ position: "fixed", top: "40%" }} />
    // <div className={style.callbackContainer}>
    //   <div className={style.loadingContainer}>
    //      <Skeleton paragraph={{ rows: 3 }} rows={4} active size="small" title={false} />
    //   </div>
    // </div>
  );
};

SigninCallback.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default SigninCallback;
