/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { AuthContext } from "contexts/AuthContext";

const AuthorisedPrivateRoute = () => (WrappedComponent) => (props) =>
  (
    <AuthContext.Consumer>
      {({ isAuthenticated }) => {
        if (!isAuthenticated) {
          window.location = `${process.env.REACT_APP_AWS_DOMAIN}/login?client_id=${process.env.REACT_APP_AWS_COGNITO_CLIENT_ID}&response_type=${process.env.REACT_APP_AWS_RESPONSE_TYPE}&redirect_uri=${process.env.REACT_APP_AWS_REDIRECT_URI}`;
          return null;
        }
        return <WrappedComponent {...props} />;
      }}
    </AuthContext.Consumer>
  );

export default AuthorisedPrivateRoute;
