import React from "react";
import PropTypes from "prop-types";
import { Button, Typography } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { SelectedProduct } from "api_services/localstorage";
import style from "../Products/products.module.scss";

const { Text } = Typography;

const ProductTile = ({ title, subtitle, status, disableSetting }) => {
  const history = useHistory();
  const selectProduct = () => {
    SelectedProduct.setProduct(title);
    history.push("/overview");
  };

  const goToSetting = () => {
    SelectedProduct.setProduct(title);
    history.push("/setting");
  };

  return (
    <>
      {status ? (
        <div className={style.activeProductTile}>
          <div className={style.productLabel}>
            <Text>{title}</Text>
          </div>
          <div className={style.productSubtitle}>
            <Text>{subtitle}</Text>
          </div>
          <div className={style.buttonSection}>
            <div>
              <Button
                type="primary"
                className={style.selectButton}
                onClick={() => selectProduct()}
              >
                SELECT
              </Button>
            </div>
            <div>
              <Button
                type="link"
                onClick={() => goToSetting()}
                disabled={disableSetting}
              >
                <SettingOutlined
                  style={
                    disableSetting ? { color: "rgba(0, 0, 0, 0.25)" } : null
                  }
                  className={style.settingIcon}
                />
                SETTINGS
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`${style.activeProductTile} ${style.inactiveProductTile}`}
        >
          <div className={style.productLabel}>
            <Text type="secondary">{title}</Text>
          </div>
          <div className={style.productSubtitle}>
            <Text type="secondary">{subtitle}</Text>
          </div>
          <div className={style.buttonSection}>
            <div>
              <Button
                type="primary"
                className={style.selectButton}
                disabled
                onClick={() => selectProduct()}
              >
                SELECT
              </Button>
            </div>
            <div>
              <Button type="link" onClick={() => goToSetting()} disabled>
                <SettingOutlined className={style.settingIcon} />
                SETTINGS
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

ProductTile.defaultProps = {
  status: false,
};

ProductTile.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default ProductTile;
