import { AUTH_TOKENS, AUTH_USER } from "constants/Constants";
import jwt from "jsonwebtoken";

export const AuthToken = {
  setToken: (key, token) => {
    localStorage.setItem(key, token);
  },
  getToken: (key) => {
    if (localStorage.getItem(key)) {
      return localStorage.getItem(key);
    }
    return null;
  },
  removeToken: () => {
    localStorage.removeItem(AUTH_TOKENS.ACCESS_TOKEN);
    localStorage.removeItem(AUTH_TOKENS.REFRESH_TOKEN);
  },
};

export const AuthUser = {
  setUser: (userData) => {
    const encodedData = jwt.sign(userData, process.env.REACT_APP_SECRET_SALT);
    localStorage.setItem(AUTH_USER.AUTH_USER, encodedData);
  },
  getUser: () => {
    if (localStorage.getItem(AUTH_USER.AUTH_USER)) {
      const userData = jwt.decode(localStorage.getItem(AUTH_USER.AUTH_USER));
      return userData;
    }
    return null;
  },
  removeUser: () => {
    localStorage.removeItem(AUTH_USER.AUTH_USER);
  },
};

export const SelectedProduct = {
  setProduct: (value) => {
    localStorage.setItem("selectedProduct", value);
  },
  getProduct: () => {
    if (localStorage.getItem("selectedProduct")) {
      return localStorage.getItem("selectedProduct");
    }
    return null;
  },
  removeProduct: () => {
    localStorage.removeItem("selectedProduct");
  },
};
