import React from "react";
import { Dot } from "react-animated-dots";
import style from "./_loading.module.scss";

const Loading = () => (
  <div className={style.loadingContainer}>
    {" "}
    Calculating
    <Dot>.</Dot>
    <Dot>.</Dot>
    <Dot>.</Dot>
  </div>
);

export default Loading;
