import React from "react";
import style from "./userGuide.module.scss";

const OutLockUserGuide = () => (
  // <div className={style.UserGuideContainer}>
  //   <div className={style.title}>User Guide</div>
  <>
    <div className={style.contentTitle}>
      The Underwriting Engine for OutLock
    </div>
    <div className={style.paragraph}>
      Machine Cover offers underwriting engines to assist actuaries,
      underwriters and providers of capacity in general. The one you’re looking
      at, is focused on a product called OutLock
    </div>

    <div className={style.contentTitle}>Underwriting Data-Driven Products</div>
    <div className={style.paragraph}>
      In general, data-driven products tend to be more profitable than is usual
      for P&C because there may be no claims adjustment, no operations, no
      per-policy underwriting, and the process is automated.
      <br />
      <br />
      However, the analysis needed prior to taking a such a product to market,
      differs from what is usual for an indemnity-oriented P&C product. A key
      difference is that there is no need to analyze both frequency as well as
      severity of loss, because severity is predetermined (by the payout amount)
      and therefore the problem reduces to analyzing frequency only. Therefore,
      we focus on frequency, i.e. determining the probability that the relevant
      product will trigger (and thus cause a payout) at some points and in some
      locations, within the 12 months that it is in effect.
      <br />
      <br />
      A key point to bear in mind is that conventional P&C products generally
      provide coverage for defined perils and their effect upon the
      policyholder. Unfortunately, life doesn’t work that way - it is nearly
      impossible to list and quantify all the ‘bad things’ that might happen to
      a business. If a business building burns down, that may be covered, while
      if a flood washes away the roads leading into a town, that may not be
      covered - and yet both are devastating when looked at from the viewpoint
      of the business owner.
      <br />
      <br />
      OutLock focuses on the economic behavior of the neighborhood to which the
      policyholder belongs - not the particular peril(s) that led to it. In that
      way, OutLock lines up much better with the pain experienced by the
      policyholder - because the policyholder is less concerned with what peril
      occurred, than the fact that they are suffering.
    </div>

    <div className={style.contentTitle}>OutLock Summary</div>
    <div className={style.paragraph}>
      The US has several million retail businesses, i.e. businesses that depend
      upon ‘foot traffic’ to generate revenues. These can range from nail salons
      and barbershops to auto dealers and supermarkets. All of these businesses
      currently buy insurance which generally deals with two kinds of losses:
      <br />
      <ul>
        <li>Those related to damage.</li>
        <li>
          Those related to business interruption associated with that damage.
        </li>
      </ul>
      There is, at the moment, no way to deal with a different kind of loss –
      where something happens that doesn’t result in an insurance claim (e.g. a
      water-main break down the street, a disease outbreak, a terror attack, a
      major employer nearby that closes, smoke from wildfires, a chemical-plant
      explosion nearby….) but which nonetheless causes a sharp drop in business
      and which is outside the power of the business to affect.
      <br />
      <br />
      OutLock offers a way to insure against this class of ‘economic
      catastrophe’. The product is an add-on to policyholders who are anyway
      buying regular insurance policies and who depend upon physical customer
      traffic.
    </div>

    <div className={style.contentTitle}>Background</div>
    <div className={style.paragraph}>
      OutLock is based upon tens of billions of individual ‘card-present’ (i.e.
      not e-commerce) card transactions across the US. This data is aggregated
      by square, filtered and analyzed, both via machine-learning algorithms as
      well as via classical statistics. Thus, we’re able to track economic
      activity down to the square mile, anywhere in the US.
      <br />
      <br />
      The land area of the US is approximately 3.8 million square miles. We
      divide this area into roughly 3 million squares. Since we have their
      locations, we can assign any merchant to one of these squares.
      <br />
      <br />
      We focus on economic activity in the square within which a given business
      happens to be. If this square is well-supplied with merchants of all kinds
      and if these merchants in aggregate, see a sharp fall in card usage (both
      in the sense of how often used and how much charged) compared to the
      nation in general, then clearly something bad is going on in that
      neighborhood - which allows the creation of an insurance product like
      OutLock.
    </div>

    <div className={style.contentTitle}>Data Source</div>
    <div className={style.paragraph}>
      We get our ‘raw’ data from Mastercard, updated weekly, generally 1-2 weeks
      behind ‘real-time’. The data covers the period from 1/1/2018 onward and is
      reported for every 24-hour period.
      <br></br>
      <br></br>
      The data provider automatically skips data for squares where there are
      either no merchants or where there are so few merchants that anyone with
      access to the data could infer which business was implied (e.g. if a given
      square has only one gas station, then any purchase of ‘gasoline and
      related automotive products’ could only have occurred there).
      <br></br>
      <br></br>
      As a result, for most days, we typically get data relating to roughly
      100,000 squares. However, merely because a square passes muster with the
      data provider does not mean that it also passes muster with Machine Cover.
      Thus, we run the data through a series of additional filters which leave
      us with approximately 22,000 ‘OutLock-worthy’ squares, i.e. squares in
      which OutLocks can be sold and serviced because those squares contain
      card-transaction data that is sufficiently rich and diverse for us. These
      squares are mainly in urban and suburban areas, where we might also
      reasonably expect to sell OutLocks.
    </div>

    <div className={style.contentTitle}>How Does Outlock Work?</div>
    <div className={style.paragraph}>
      The essence of the product is that if economic conditions in the
      neighborhood of a given policyholder (the ‘square’ in which the
      policyholder is located) deteriorate sharply compared to the nation, the
      policy triggers.
      <br />
      <br />
      This is a ‘peril-less’ policy - it does not concern itself with WHY the
      local economy deteriorated - just that it did.
      <br />
      <br />
      Let us say a policyholder buys an OutLock policy. For a trigger to happen,
      3 things must all be true:
      <ol type="a">
        <li>
          The policyholder’s ‘square’ should see a month-over-month fall in
          economic activity by a predetermined percentage compared to the
          nation.
        </li>
        <li>
          This fall should not be predictable (in the sense of occurring after
          Christmas or after a tourist season) which is verified by looking at
          the same period over a 5-year span.
        </li>
        <li>
          This fall must be ongoing for (at least) 2 successive months, i.e.
          things went bad and remained bad, they didn’t get fixed quickly.
        </li>
      </ol>
    </div>

    {/* <div className={style.contentTitle}>Why Would Someone Buy Outlock?</div>
      <div className={style.paragraph}>
        <ol type="1">
          <li>
            It offers the policyholder a way to address issues that may severely
            affect their business, but for which there is no way to offload risk
            – essentially anything that is both local and not the fault of the
            policyholder, and which also does not damage the assets of the
            business, thus not triggering their regular insurance.
          </li>
          <li>
            While no one likes to buy insurance, the logic for buying OutLock is
            the same as for any other insurance policy – it is easier to come up
            with a small amount of money each month or each year than to
            suddenly come up with a large amount when disaster strikes.
          </li>
          <li>
            The policyholder gets paid a lot more promptly than via a
            conventional policy – which is really important to smaller
            businesses that don’t have a few weeks of working capital.
          </li>
        </ol>
      </div> */}
    {/*
      <div className={style.contentTitle}>Other things to keep in mind</div>
      <div className={style.paragraph}>
        <ul>
          <li>
            OutLock is generally supplemental to insurance that businesses buy
            (or have already bought) anyway.
          </li>
          <li>It is one-and-done, i.e. if it triggers, it ends immediately.</li>
          <li>
            The thing that triggers is a square - which causes any OutLocks that
            happen to have been sold into that square, to automatically trigger.
            As such there is no need to view the economic performance of the
            policyholder.
          </li>
          <li>
            Any time a square triggers, that square is automatically deemed
            ‘OutLock-unworthy’ for the next 365 days, i.e. we do not want to
            sell (new) OutLocks into squares that are already doing demonstrably
            badly.
          </li>
        </ul>
      </div> */}

    <div className={style.contentTitle}>
      Using the Underwriting Engine for OutLock
    </div>
    <div className={style.paragraph}>
      Once a user of the Underwriting Engine has logged in and selected OutLock,
      it works as follows:
    </div>

    <div className={style.contentTitle}>SETTINGS</div>
    <div className={style.paragraph}>
      The user may choose to alter the SETTINGS, which are available by clicking
      on SETTINGS in the OutLock box BEFORE clicking on SELECT. This is only
      necessary when OutLock has already been in the market for long enough that
      it has accumulated a significant sales history which can be taken into
      account while underwriting.
      <br />
      <br />
      Clicking on SETTINGS leads to a page where OutLock can be operated upon 3
      different kinds of 'raw data' with different weightages for each.
      <ol type="1">
        <li>
          The first is the 'raw data' on card activity which provides
          information about economic activity in the sales region.{" "}
          <u>
            By default, this is checked, and its weightage is 100%. If the user
            is in this page prior to any significant OutLock sales, it is best
            to leave as is
          </u>
          .
        </li>
        <li>
          It is possible that the user may possess data relevant to 'expected
          OutLock sales' e.g. their historical volume of sales of other
          insurance products, which the user feels may modify the actual sales
          of OutLock.
        </li>
        <li>
          It is possible that if the user already has experience in selling
          OutLock, they may wish to use that experience to modify the raw data
          in (1) above. If so, as in (2), they would check the relevant box,
          assign a weightage to this kind of data and upload the data into the
          UE. The details of what form the upload ought to take, may be obtained
          from Machine Cover.
        </li>
      </ol>
      Once the settings are viewed or changed, the user can exit the relevant
      page and enter the Main Page as explained below.
    </div>

    <div className={style.contentTitle}>Main Page</div>
    <div className={style.paragraph}>
      In the Main Page, the user can enter a number of settings that define the
      OutLock product which they plan to design/test. Specifically, they can do
      the following:
      <br />
      <ol type="1">
        <li>
          <u>Which States&nbsp;</u>
          -&nbsp;select which states this product will be sold in. This has
          obvious implications for market size and risk profile. Machine Cover
          has created a number of ‘canned’ sets of states for convenience, or
          the user can select their own combination.
        </li>
        <li>
          <u>Squares or Zip Codes&nbsp;</u>
          -&nbsp;Here we allow you to analyze the data by squares or by zip
          codes. We’ve pre-selected analysis by squares, since they tend to lead
          to much finer-grained results.
        </li>
        <li>
          <u>Trigger Duration&nbsp;</u>
          -&nbsp;this allows you to select whether the ‘pain’ of an economic
          plunge endures for 2 months (the default) or for 1 month. We don’t
          advise changing this from the default, since doing so might lead to an
          overlap with nat. cat. events.
        </li>
        <li>
          <u>Risk Threshold&nbsp;</u>
          -&nbsp;this allows the user to set a ‘risk threshold’ i.e. how much
          worse should a given square be, compared to the nation before it can
          trigger? The user is welcome to try various risk thresholds. We
          suggest going to market with risk thresholds of between 30% and 40% -
          lower and you might pay out too much, higher and you might inhibit
          purchases of the product.
        </li>
        <li>
          <u>Trigger Propensity&nbsp;</u>
          -&nbsp;it turns out that the more volatile the economic activity in a
          square is on a regular basis, the higher is its propensity to trigger.
          This field allows the user to exclude squares from sales of OutLock
          (and thus triggering and payout) by specifically excluding those
          squares that are most likely to trigger. We’ve pre-selected the
          exclusion of the ‘worst’ 10% of squares. Best to leave as is unless
          you’ve got a strong reason to change it.
        </li>
        <li>
          <u>Exclusions&nbsp;</u>
          -&nbsp;Whether to impose exclusions. Please note that this only refers
          to exclusions that can be automatically determined - you can certainly
          impose manually determined exclusions in the policy form (although we
          discourage it). We’ve included two exclusions of which one is enabled
          by default:
          <ol type="a">
            <li>
              (Disabled by default) The declaration by the WHO of a global
              pandemic and its lifting. This is relevant to all states in the
              US.
            </li>
            <li>
              (Enabled by default) The declaration of a lockdown by governmental
              authorities, and its lifting. The exclusion applies only to the
              state to which the lockdown is relevant. No OutLock may trigger in
              a given state for any reason while an exclusion is in effect,
              relevant to that state.
            </li>
          </ol>
        </li>
      </ol>
      Once the user sets the values above, they can click IMPOSE CAPS, which
      leads to the next section:
    </div>

    <div className={style.contentTitle}>Impose Caps Dialog</div>
    <div className={style.paragraph}>
      By the time the user has got to this dialog, they’ve already defined the
      desired states, the risk threshold and exclusions. This dialog allows them
      to further specify the definition of their product before doing a
      ‘what-if’ with all these values.
      <br />
      <br />
      The system takes 15-30 seconds to generate this dialog mainly because
      that’s how long it takes to generate its left side (see below).
      <br />
      <br />
      The left side of the dialog shows the user what they’ve already selected
      and also shows them the distribution of economic activity in the squares
      they’ve selected, from the lowest-activity squares at left to the
      highest-activity squares at right.
      <br />
      <br />
      In the right side of this dialog, the user is expected to enter the
      following:
      <ol type="1">
        <li>
          <u>Annual Premium per Policy&nbsp;</u>
          -&nbsp;How much will be charged the policyholder each year for this
          policy? The number is expressed in thousands of dollars (so for
          instance a $1,700 annual premium would be expressed as 1.7).
        </li>
        <li>
          <u>Payout if Policy Triggers&nbsp;</u>
          -&nbsp;How much is paid out to the policyholder if the policy
          ‘triggers’? The number is expressed in thousands of dollars.
        </li>
        <li>
          <u>How many Policies Sold?&nbsp;</u>
          -&nbsp;How many policies are expected to be sold for the purposes of
          this simulation? The number is expressed in thousands.
        </li>
        <li>
          <u>Payout Risk&nbsp;</u>
          -&nbsp;What percentage of primary payout risk is contemplated? This
          should be 100 unless the simulation is being conducted by one of
          multiple parties that are sharing primary risk. Expressed as a
          percentage.
        </li>
        <li>
          <u>Primary Capacity&nbsp;</u>
          -&nbsp;What is the total amount of primary capacity to be deployed? In
          other words, if an economic cell triggers, this is the pool of money
          out of which payouts are to be made, until it is exhausted (and
          reinsurance kicks in).
        </li>
        <li>
          <u>Reinsurance Capacity&nbsp;</u>
          -&nbsp;What is the amount of reinsurance capacity to be deployed?
          Strictly speaking this entry is not relevant since all payouts that
          occur past the point where primary capacity is exhausted, come out of
          reinsurance. However, it may be informative to see how sharply the
          probability of payout via reinsurance falls, because this engine
          supplies the probability that reinsurance is dipped into at all, as
          well as the probability that any amount ‘dedicated’ to reinsurance, is
          used up.
        </li>
        <li>
          <u>Low Cap&nbsp;</u>
          -&nbsp;What is the maximum risk that can be taken in any square that
          falls into the lowest-performing percentile (at the leftmost end of
          the curve on the left of the dialog)? Expressed in thousands of
          dollars. The system automatically suggests a value for this field.
        </li>
        <li>
          <u>High Cap&nbsp;</u>
          -&nbsp;What is the maximum risk that can be taken in any square that
          falls into the highest-performing percentile (at the rightmost end of
          the curve on the left of the dialog)? Expressed in thousands of
          dollars. The system automatically suggests a value for this field.
        </li>
        <li>
          <u>Number of hot-zones&nbsp;</u>
          -&nbsp;A hot-zone is defined as a 9x9 square region (for example a
          city and its inner suburbs) within which at least 10 squares are
          ‘OutLock-worthy’ (OutLock policies can be sold into these squares).
          The idea of imposing risk caps on hot-zones is that an economic plunge
          may not confine itself to one or two squares – it may affect a whole
          city. Thus, we want to make sure that we haven’t concentrated too much
          risk in any single region. We accomplish this by defining how many
          hot-zones we want to consider in this field and then imposing a risk
          cap on all such hot-zones in the next field.
        </li>
        <li>
          <u>Maximum risk per hot-zone&nbsp;</u>
          -&nbsp;The maximum amount of risk this user wants to take on in any
          single hot-zone. Expressed in millions of dollars.
        </li>
      </ol>
      Once the user has entered all these values, they can click on any of 4
      buttons: DATA DOWNLOAD, QUICK ANALYSIS, DEEP ANALYSIS and MAKE PRODUCT.
    </div>

    <div className={style.contentTitle}>DATA DOWNLOAD</div>
    <div className={style.paragraph}>
      This allows the user to download the data that is used to make the run
      defined by the current settings (so for example if the product is to be
      sold in only 2 states then the downloaded data only pertains to those
      states).
    </div>

    <div className={style.contentTitle}>QUICK ANALYSIS</div>
    <div className={style.paragraph}>
      The main purpose of this button is to allow the user to get answers that
      are directionally correct in a couple of minutes or less. It uses 100
      Monte Carlo simulations and the results are conveyed to the user via
      email. The results show the expected loss ratio, the probability that the
      entire primary capacity that was defined, is used up (and thus reinsurance
      is dipped into), as well as the probability that the entire defined
      reinsurance amount is used up.
      <br />
      <br />
      In addition, the following is also available in the email via hyperlinks:
      <ul>
        <li>
          A listing of all the squares and the number of triggers in each square
          during the months under consideration.
        </li>
        <li>
          A curve of the Maximum Expected Payout vs Probability of Occurrence as
          computed during the simulation.
        </li>
        <li>
          A tabular representation of the Maximum Expected Payout vs Probability
          of Occurrence as computed during the simulation.
        </li>
      </ul>
    </div>

    <div className={style.contentTitle}>DEEP ANALYSIS</div>
    <div className={style.paragraph}>
      This is similar to QUICK ANALYSIS except it runs for a lot longer
      (generally 15 minutes or less) and it uses 1000 Monte Carlo analyses.
    </div>

    <div className={style.contentTitle}>MAKE PRODUCT</div>
    <div className={style.paragraph}>
      If a user has concluded that it makes sense to take this product to
      market, then a way to do that is to use the Machine Cover Platform to
      manage the sales of this product. The first step in this process is to
      click on MAKE PRODUCT so that the settings in the current run of the
      Underwriting Engine are captured as a JSON file which can then be expanded
      upon by Machine Cover’s Product Definition System. For a complete
      description of Machine Cover’s Product Definition System, please look at
      the file entitled <b>Machine Cover Product Definition Template V3.0</b>.
      At the moment this button is used only by Machine Cover employees.
    </div>

    <div className={style.contentTitle}>
      Things to keep in mind before defining & selling
    </div>
    <div className={style.paragraph}>
      <ul>
        <li>
          <u>Fraud & Moral Hazard&nbsp;</u>
          -&nbsp;It is difficult to commit fraud around OutLock since the
          measure of economic activity we use – billions of card transactions –
          cannot be ‘fiddled with’ by buyer or seller.
        </li>
        <li>
          <u>Adverse Selection&nbsp;</u>
          -&nbsp;The only way to experience adverse selection in LoanProtect is
          where some squares have a higher inherent propensity to trigger than
          others. We’ve already analyzed the historical data for this
          possibility and eliminated such squares. In addition, since the design
          of OutLock anyway prevents triggers resulting from predictable drops,
          that issue also goes away.
        </li>
        <li>
          <u>Basis Risk&nbsp;</u>
          -&nbsp;conventionally defined as the difference between loss as
          modeled and loss as actually experienced. In the case of Outlock, the
          notion of basis risk is irrelevant since the policyholder receives a
          defined amount when a defined event occurs, and the payout is
          unrelated to loss as experienced by the policyholder.
        </li>
        <li>
          <u>Total Risk&nbsp;</u>
          -&nbsp;This is usual for any insurance product – the ability to impose
          a cap upon total risk taken on.
        </li>
        <li>
          <u>Concentration/Aggregation Risk&nbsp;</u>
          -&nbsp;Finally, we deal with the class of risk that seems most
          perilous to underwriters – the fear that too much risk will be
          concentrated in one area and if that area happens to trigger, the
          losses may be calamitous. This is dealt with through the ability to
          impose risk caps on a per-square basis as well as on a multi-square
          (hot-zone) basis.
        </li>
      </ul>
    </div>

    {/* <div className={style.contentTitle}>Regulatory concerns</div>
    <div className={style.paragraph}>
      Generally speaking, any insurance product involves an ‘event’ (some type
      of adverse and fortuitous occurrence that raises the possibility of a
      claim/payout) and a ‘loss’ (some type of loss suffered by the policyholder
      as a result of this event). In the case of OutLock, the ‘event’ certainly
      exists, but the notion of ‘loss’ in the indemnity sense does not exist.
      However, in order to regulate OutLock as an insurance product, it may be
      necessary for the policyholder to attest to the fact that they did suffer
      a loss as a result of the ‘event’, that met or exceeded the amount they
      are being paid via OutLock. The following factors may be kept in mind:
      <ul>
        <li>The policyholder is not expected to exactly define their loss.</li>
        <li>
          Provided the payout is small, no one can argue that the policyholder
          will get an ‘economic windfall’ from the payout, nor that the payout
          is not reasonably in the ballpark of the loss that might be suffered
          from such events.
        </li>
        <li>
          The high speed of the payout is particularly relevant to policyholders
          without big capital reserves.
        </li>
      </ul>
      We suggest trying to file this product as non-admitted in view of the fact
      that (a) profitability is high enough to allow for premium taxes (b) the
      regulatory load is a lot lighter and (c) no competing product is offered
      to the best of our knowledge.
    </div> */}
  </>
);

export default OutLockUserGuide;
