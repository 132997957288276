import React, { createContext, useState, useContext } from "react";

/**
 * Provider for user context which holds user value
 */
const SidebarContext = createContext();

/**
 * Instead of importing context using useContext
 * everytime inside components, this function allows
 * to directly import the context without repeating
 * the below chunk of code.
 *
 * What it solves is the problem of not using useContext
 * everywhere for eg:
 *
 *  const context = useContext(AuthContext); Not Recommended
 *
 * Better code
 *  import { context } from 'AuthContext';
 *
 */
const useSidebarContext = () => {
  const context = useContext(SidebarContext);
  if (context === undefined) {
    throw new Error("useSidebarContext must be used within a SidebarContext");
  }
  return context;
};

export { useSidebarContext, SidebarContext };

/**
 * @description - Sidebar context provide component which sets all
 * the user related value and provides methods to get them in
 * oother components
 * @param {*} props
 */
const SidebarContextProvider = (props) => {
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <SidebarContext.Provider
      value={{
        visible,
        showDrawer,
        onClose,
      }}
    >
      {props.children}
    </SidebarContext.Provider>
  );
};

export default SidebarContextProvider;
