import { AUTH_TOKENS } from "constants/Constants";
import axios from "axios";
import { message } from "antd";
import { AuthToken, AuthUser, SelectedProduct } from "./localstorage";

const connectionInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  timeout: 10000,
  // withCredentials: true,
  crossdomain: true,
});

export const dummyInstance = axios.create({
  baseURL: "http://editor.swagger.io/",
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
});

const tokenUrl = `${process.env.REACT_APP_AWS_DOMAIN}/oauth2/token`;

const getNewToken = async () => {
  try {
    const params = new URLSearchParams();
    params.append("grant_type", "refresh_token");
    params.append(
      "refresh_token",
      AuthToken.getToken(AUTH_TOKENS.REFRESH_TOKEN)
    );
    params.append("client_id", process.env.REACT_APP_AWS_COGNITO_CLIENT_ID);
    const res = await axios.post(tokenUrl, params, {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    });
    if (res && res.data) {
      AuthToken.setToken(AUTH_TOKENS.ACCESS_TOKEN, res.data.access_token);
    }
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 *  set request interceptors before sending request
 */
connectionInstance.interceptors.request.use(
  (config) => {
    const idToken = AuthToken.getToken(AUTH_TOKENS.ACCESS_TOKEN);
    if (idToken) {
      config.headers.Authorization = `Bearer ${idToken}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

connectionInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 440) {
      AuthToken.removeToken();
      AuthUser.removeUser();
      SelectedProduct.removeProduct();
      window.location = `${process.env.REACT_APP_AWS_DOMAIN}/logout?client_id=${process.env.REACT_APP_AWS_COGNITO_CLIENT_ID}&response_type=${process.env.REACT_APP_AWS_RESPONSE_TYPE}&redirect_uri=${process.env.REACT_APP_AWS_REDIRECT_URI}`;
      return Promise.reject(error);
    }

    if (error.response.status !== 401) {
      return Promise.reject(error);
    }

    if (error.response.status === 401) {
      message.error("Session expired. Please sign-in again.");
      AuthToken.removeToken();
      window.location = `${process.env.REACT_APP_AWS_DOMAIN}/logout?client_id=${process.env.REACT_APP_AWS_COGNITO_CLIENT_ID}&response_type=${process.env.REACT_APP_AWS_RESPONSE_TYPE}&redirect_uri=${process.env.REACT_APP_AWS_REDIRECT_URI}`;
      return Promise.reject(error);
    }

    try {
      const tokenResponse = await getNewToken();
      if (tokenResponse.data.id_token) {
        const { config } = error;
        config.headers.Authorization = `Bearer ${tokenResponse.data.id_token}`;
        return await axios.request(config);
      }
      AuthToken.removeToken();
      window.location = `${process.env.REACT_APP_AWS_DOMAIN}/logout?client_id=${process.env.REACT_APP_AWS_COGNITO_CLIENT_ID}&response_type=${process.env.REACT_APP_AWS_RESPONSE_TYPE}&redirect_uri=${process.env.REACT_APP_AWS_REDIRECT_URI}`;
      return Promise.reject(error);
    } catch (err) {
      return Promise.reject(err);
    }
  }
);

export default connectionInstance;
