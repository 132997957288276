import React, { useState } from "react";
import PropTypes from "prop-types";
import "./floatingLabel.scss";

const FloatLabel = ({ children, labelName, valueText, inputName }) => {
  const [focus, setFocus] = useState(false);
  const labelClass =
    focus || (valueText && valueText.length !== 0)
      ? "label label-float"
      : "label";
  return (
    <div
      className="float-label"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      {children}
      <label htmlFor={inputName} className={labelClass}>
        {labelName}
      </label>
    </div>
  );
};

FloatLabel.defaultProps = {
  valueText: "",
};

FloatLabel.propTypes = {
  children: PropTypes.element.isRequired,
  labelName: PropTypes.string.isRequired,
  valueText: PropTypes.string,
  inputName: PropTypes.string.isRequired,
};

export default FloatLabel;
