import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import {
  Button,
  Checkbox,
  Input,
  Typography,
  Radio,
  Modal,
  Upload,
  message,
  Spin,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { upload, FileLogo } from "assets";
import { AuthToken, SelectedProduct } from "api_services/localstorage";
import { AUTH_TOKENS } from "constants/Constants";
import axios from "axios";
import { readString } from "react-papaparse";
import { useAuthContext } from "contexts/AuthContext";
import connectionInstance from "api_services/connection";
import style from "./productSetting.module.scss";

const { Text } = Typography;
const { Dragger } = Upload;

const ProductSetting = () => {
  const history = useHistory();
  const userContext = useAuthContext();
  const { user } = userContext;
  const selectedProduct = SelectedProduct.getProduct();
  const [cardActivityDataStatus, setcardActivityDataStatus] = useState(true);
  const [cardActivityDataInput, setcardActivityDataInput] = useState("");
  const [saleHistoryInput, setsaleHistoryInput] = useState("");
  const [outlockSalesDataInput, setoutlockSalesDataInput] = useState("");
  const [saleHistoryStatus, setsaleHistoryStatus] = useState(true);
  const [outlockSalesDataStatus, setoutlockSalesDataStatus] = useState(true);
  const [saleHistoryRadioButton, setsaleHistoryRadioButton] = useState("state");
  const [disable, setdisable] = useState({
    cardActivityDataInput: false,
    saleHistoryInput: false,
    outlockSalesDataInput: false,
  });
  const [showFileUploadModal, setshowFileUploadModal] = useState(false);
  const [fileInfo, setfileInfo] = useState(null);
  const [fileError, setfileError] = useState(null);
  const [showLoading, setShowLoading] = useState(false);
  const [savedFiles, setsavedFiles] = useState({
    T00: null,
    T01: null,
    T10: null,
  });

  useEffect(() => {
    getUploadedFiles();
    getSettingsData();
  }, []);

  const getUploadedFiles = async () => {
    try {
      setShowLoading(true);
      const uploadedFileResponse = await connectionInstance.get(
        `${process.env.REACT_APP_API_URL}/probability/setting_files?user_email=${user.email}&request_type=fetch&file_type=T00&product=${selectedProduct}`
      );
      if (uploadedFileResponse && uploadedFileResponse.data) {
        setsavedFiles({ ...uploadedFileResponse.data });
        setShowLoading(false);
      }
    } catch (error) {
      setShowLoading(false);
      if (
        error.response &&
        error.response.status &&
        error.response.status !== 401
      ) {
        message.error("Error!! We are unable to fetch the uploaded files.");
      }
    }
  };

  const handleKeyDown = (evt) => {
    if (
      evt.key.toLowerCase() === "backspace" ||
      evt.key.toLowerCase() === "." ||
      evt.key.toLowerCase() === "tab"
    ) {
      return;
    }
    if (isNaN(evt.key)) {
      evt.preventDefault();
    }
    if (
      evt.target.value.indexOf(".") === -1 &&
      evt.target.value.toString().length > 3
    ) {
      evt.preventDefault();
    }
    if (
      evt.target.value.indexOf(".") > -1 &&
      evt.target.value.toString().length > 4
    ) {
      evt.preventDefault();
    }
  };

  const getSettingsData = async () => {
    try {
      setShowLoading(true);
      const response = await connectionInstance.get(
        `${
          process.env.REACT_APP_API_URL
        }/probability/user_settings?user_email=${
          user.email
        }&card_activity=${0}&sales_history=${0}&outlock_sales=${0}&product=${selectedProduct}`
      );
      if (response && response.data) {
        setcardActivityDataInput(response.data.card_activity);
        setsaleHistoryInput(response.data.sales_history);
        setoutlockSalesDataInput(response.data.outlock_sales);
        if (response.data.card_activity == 0) {
          setcardActivityDataInput("");
          setcardActivityDataStatus(false);
          setdisable((prev) => ({
            ...prev,
            cardActivityDataInput: true,
          }));
        }
        if (response.data.sales_history == 0) {
          setsaleHistoryInput("");
          setsaleHistoryStatus(false);
          setdisable((prev) => ({
            ...prev,
            saleHistoryInput: true,
          }));
        }
        if (response.data.outlock_sales == 0) {
          setoutlockSalesDataInput("");
          setoutlockSalesDataStatus(false);
          setdisable((prev) => ({
            ...prev,
            outlockSalesDataInput: true,
          }));
        }
        setTimeout(() => {
          setShowLoading(false);
        }, 2000);
      }
    } catch (error) {
      setShowLoading(false);
      if (
        error.response &&
        error.response.status &&
        error.response.status !== 401
      ) {
        message.error(
          "We are unable to fetch the previous settings now. Please try after a while."
        );
      }
    }
  };

  const handleSaveButtonClick = async () => {
    try {
      setShowLoading(true);
      const response = await connectionInstance.put(
        `${
          process.env.REACT_APP_API_URL
        }/probability/user_settings?user_email=${user.email}&card_activity=${
          cardActivityDataInput || 0
        }&sales_history=${saleHistoryInput || 0}&outlock_sales=${
          outlockSalesDataInput || 0
        }&product=${selectedProduct}`
      );
      if (response) {
        setShowLoading(false);
        if (response.data && response.data.processed) {
          history.push("/");
          // message.success("The settings has been saved successfully.");
        }
      }
    } catch (error) {
      setShowLoading(false);
      if (
        error.response &&
        error.response.status &&
        error.response.status !== 401
      ) {
        message.error(
          "We are unable to save the settings now. Please try after a while."
        );
      }
    }
  };

  const uploadCSVFile = async () => {
    try {
      setShowLoading(true);
      const uploadURLResponse = await connectionInstance.get(
        `${
          process.env.REACT_APP_API_URL
        }/probability/setting_files?user_email=${
          user.email
        }&request_type=upload&file_type=${
          showFileUploadModal === "outlockSalesData"
            ? "T10"
            : showFileUploadModal === "saleHistory-zip"
            ? "T01"
            : "T00"
        }&product=${selectedProduct}`
      );

      if (
        uploadURLResponse &&
        uploadURLResponse.data &&
        uploadURLResponse.data.presigned_url
      ) {
        if (fileInfo) {
          axios
            .put(uploadURLResponse.data.presigned_url, fileInfo)
            .then((response) => {
              getUploadedFiles();
              setshowFileUploadModal(null);
              setfileInfo(null);
              setShowLoading(false);
            })
            .catch((error) => {
              setShowLoading(false);
              message.error("Error!! Please try after a while.");
            });
        }
      }
    } catch (error) {
      setShowLoading(false);
      if (
        error.response &&
        error.response.status &&
        error.response.status !== 401
      ) {
        message.error("Error!! Please try after a while.");
      }
    }
  };

  const data = {
    // name: 'file',
    multiple: false,
    accept: ".csv",
    headers: {
      Authorization: `Bearer ${AuthToken.getToken(AUTH_TOKENS.ACCESS_TOKEN)}`,
    },
    showUploadList: false,
    onChange(info) {
      const { status } = info.file;
      const { fileList, file } = info;
      if (status !== "uploading") {
      }
      // if (status === 'done') {
      // message.success(`${info.file.name} file uploaded successfully.`);
      // } else if (status === 'error') {
      // message.error(`${info.file.name} file upload failed.`);
      // }
    },
    async beforeUpload(file) {
      delete file.uid;
      const reader = new FileReader();

      reader.onload = (e) => {
        const papaConfig = {
          complete: (results) => {
            if (showFileUploadModal === "outlockSalesData") {
              if (results.data[0].length !== 4) {
                setfileError(
                  "File format is incorrect. Please refer to the sample"
                );
                setfileInfo(null);
              } else if (
                results.data[0].includes("Policy Number") &&
                results.data[0].includes("Address") &&
                results.data[0].includes("State") &&
                results.data[0].includes("Zip Code")
              ) {
                setfileInfo(file);
                setfileError(null);
              } else {
                setfileError(
                  "File format is incorrect. Please refer to the sample"
                );
                setfileInfo(null);
              }
            } else if (showFileUploadModal === "saleHistory-zip") {
              if (results.data[0].length !== 3) {
                setfileError(
                  "File format is incorrect. Please refer to the sample"
                );
              } else if (
                results.data[0].includes("Number of policies") &&
                results.data[0].includes("State") &&
                results.data[0].includes("Zip Code")
              ) {
                setfileInfo(file);
                setfileError(null);
              } else {
                setfileError(
                  "File format is incorrect. Please refer to the sample"
                );
                setfileInfo(null);
              }
            } else if (results.data[0].length !== 2) {
              setfileError(
                "File format is incorrect. Please refer to the sample"
              );
              setfileInfo(null);
            } else if (
              results.data[0].includes("Number of policies") &&
              results.data[0].includes("State")
            ) {
              setfileInfo(file);
              setfileError(null);
            } else {
              setfileError(
                "File format is incorrect. Please refer to the sample"
              );
              setfileInfo(null);
            }
          },
          // download: true,
          error: (error, file) => {
            //   setShowLoading(false);
            console.log("Error while parsing:", error, file);
          },
        };
        readString(e.target.result, papaConfig);
      };
      reader.readAsText(file);
      // Prevent upload
      return false;
    },
    onDrop(e) {},
  };

  return (
    <div className={style.productSettingContainer}>
      <Spin
        spinning={showLoading && !showFileUploadModal}
        style={{ position: "fixed", top: "20%" }}
      >
        <Modal
          visible={!!showFileUploadModal}
          className="fileUploadModal"
          title={null}
          centered
          maskClosable={false}
          closable
          onCancel={() => {
            setfileInfo(null);
            setshowFileUploadModal(null);
            setfileError(null);
          }}
          onOk={() => setshowFileUploadModal(null)}
          footer={
            fileInfo &&
            fileInfo.name && [
              <Button
                // key="back"
                type="primary"
                style={{ float: "center" }}
                disabled={!(fileInfo && fileInfo.name)}
                onClick={() => uploadCSVFile()}
                loading={showLoading}
              >
                UPLOAD
              </Button>,
            ]
          }
        >
          <div className="file-upload">
            <div className="upload-text">UPLOAD .csv FILE</div>
            <div>
              {fileInfo && fileInfo.name ? (
                <>
                  <div className="uploaded-file-info">
                    <div style={{ width: "100%", textAlign: "right" }}>
                      <Button
                        // type="primary"
                        icon={<CloseOutlined />}
                        type="link"
                        onClick={() => {
                          setfileInfo(null);
                          setfileError(null);
                        }}
                      />
                    </div>
                    <div>
                      <img
                        src={FileLogo}
                        alt="icon"
                        style={{ height: "80px" }}
                      />
                    </div>
                    <div className="file-name">
                      <strong> {fileInfo.name}</strong>
                    </div>
                    <div className="file-size"> {fileInfo.size} KB</div>

                    {/* <div className="remove-file">
                                    {' '}Remove
                                </div> */}
                  </div>
                </>
              ) : (
                <div className="file-dragger">
                  <Dragger {...data}>
                    <div className="ant-upload-drag-icon">
                      <img src={upload} alt="icon" />
                    </div>
                    <div className="drag-text">Drag files here or browse</div>
                  </Dragger>
                </div>
              )}
            </div>

            <div style={{ marginTop: "10px" }}>
              {!!fileError && <Text className="errorText">{fileError}</Text>}
            </div>
          </div>
        </Modal>

        <div className={style.title}>
          Settings for
          {selectedProduct}
        </div>
        <div className={style.productSettingContent}>
          <div className={style.headings}>
            <span style={{ float: "left" }}>
              <strong>Data Usage</strong>
            </span>
            <span style={{ float: "right" }}>
              <strong>Weightage</strong>
            </span>
          </div>
          <div className={style.centerAlignContent}>
            <Checkbox
              checked={cardActivityDataStatus}
              onChange={(e) => {
                if (!saleHistoryStatus && !outlockSalesDataStatus) {
                  return;
                }
                setcardActivityDataStatus(e.target.checked);
                if (e.target.checked) {
                  setdisable((prev) => ({
                    ...prev,
                    cardActivityDataInput: false,
                  }));
                } else {
                  setcardActivityDataInput("");
                  setdisable((prev) => ({
                    ...prev,
                    cardActivityDataInput: true,
                  }));
                  if (
                    !saleHistoryStatus ||
                    (!outlockSalesDataStatus &&
                      !(outlockSalesDataStatus && saleHistoryStatus))
                  ) {
                    if (saleHistoryStatus) {
                      setsaleHistoryInput(100);
                    } else {
                      setoutlockSalesDataInput(100);
                    }
                  }
                }
              }}
            >
              <Text
                className={
                  cardActivityDataStatus ? style.active : style.inactive
                }
              >
                Card Activity Data
              </Text>
            </Checkbox>

            <div style={{ position: "absolute", right: 0 }}>
              <Input
                className={style.inputBox}
                disabled={disable.cardActivityDataInput}
                value={cardActivityDataInput}
                onChange={(e) => setcardActivityDataInput(e.target.value)}
                onKeyDown={(evt) => handleKeyDown(evt)}
                onBlur={() => {
                  if (cardActivityDataInput == "0") {
                    setcardActivityDataInput("");
                    setcardActivityDataStatus(false);
                    setdisable((prev) => ({
                      ...prev,
                      cardActivityDataInput: true,
                    }));
                    if (
                      !saleHistoryStatus ||
                      (!outlockSalesDataStatus &&
                        !(outlockSalesDataStatus && saleHistoryStatus))
                    ) {
                      if (saleHistoryStatus) {
                        setsaleHistoryInput(100);
                      } else {
                        setoutlockSalesDataInput(100);
                      }
                    }
                  }
                }}
              />
              <span className={style.percentSymbol}>%</span>
            </div>
          </div>
          <div style={{ marginTop: "19px" }}>
            <Checkbox
              checked={saleHistoryStatus}
              onChange={(e) => {
                if (!cardActivityDataStatus && !outlockSalesDataStatus) {
                  return;
                }
                setsaleHistoryStatus(e.target.checked);
                if (e.target.checked) {
                  setdisable((prev) => ({
                    ...prev,
                    saleHistoryInput: false,
                  }));
                } else {
                  setsaleHistoryInput("");
                  setdisable((prev) => ({
                    ...prev,
                    saleHistoryInput: true,
                  }));
                  if (
                    !cardActivityDataStatus ||
                    (!outlockSalesDataStatus &&
                      !(outlockSalesDataStatus && cardActivityDataStatus))
                  ) {
                    if (cardActivityDataStatus) {
                      setcardActivityDataInput(100);
                    } else {
                      setoutlockSalesDataInput(100);
                    }
                  }
                }
              }}
            >
              <Text
                className={saleHistoryStatus ? style.active : style.inactive}
              >
                Sale History
              </Text>
            </Checkbox>
          </div>
          <div className={style.centerAlignContent}>
            <div style={{ display: "block", marginLeft: "24px" }}>
              <div>
                <Radio.Group
                  onChange={(e) => setsaleHistoryRadioButton(e.target.value)}
                  value={saleHistoryRadioButton}
                >
                  <Radio
                    value="state"
                    disabled={disable.saleHistoryInput}
                    key="1"
                  >
                    <Text
                      className={
                        saleHistoryRadioButton === "state"
                          ? style.active
                          : style.inactive
                      }
                    >
                      By State
                    </Text>
                  </Radio>
                  <Radio
                    value="zip"
                    disabled={disable.saleHistoryInput}
                    key="2"
                  >
                    <Text
                      className={
                        saleHistoryRadioButton === "zip"
                          ? style.active
                          : style.inactive
                      }
                    >
                      By Zip code
                    </Text>
                  </Radio>
                </Radio.Group>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "14px",
                }}
              >
                <Button
                  className={style.updateButton}
                  type="primary"
                  disabled={disable.saleHistoryInput}
                  onClick={() =>
                    setshowFileUploadModal(
                      `saleHistory-${saleHistoryRadioButton}`
                    )
                  }
                >
                  UPLOAD DATA
                </Button>
                <Text className={style.fileNameText}>
                  {saleHistoryRadioButton === "zip"
                    ? savedFiles.T01
                    : savedFiles.T00}
                </Text>
              </div>
              <div>
                <Link
                  to={
                    saleHistoryRadioButton === "zip"
                      ? "/samples/Sale_History_By_Zip_Code.csv"
                      : "/samples/Sale_History_By_State.csv"
                  }
                  target="_blank"
                  download
                >
                  <Button
                    style={{ padding: 0 }}
                    disabled={disable.saleHistoryInput}
                    type="link"
                  >
                    Download Sample
                  </Button>
                </Link>
              </div>
            </div>
            <div style={{ position: "absolute", right: 0 }}>
              <Input
                className={style.inputBox}
                disabled={disable.saleHistoryInput}
                value={saleHistoryInput}
                onChange={(e) => setsaleHistoryInput(e.target.value)}
                onKeyDown={(evt) => handleKeyDown(evt)}
                onBlur={() => {
                  if (saleHistoryInput == "0") {
                    setsaleHistoryInput("");
                    setsaleHistoryStatus(false);
                    setdisable((prev) => ({
                      ...prev,
                      saleHistoryInput: true,
                    }));
                    if (
                      !cardActivityDataStatus ||
                      (!outlockSalesDataStatus &&
                        !(outlockSalesDataStatus && cardActivityDataStatus))
                    ) {
                      if (cardActivityDataStatus) {
                        setcardActivityDataInput(100);
                      } else {
                        setoutlockSalesDataInput(100);
                      }
                    }
                  }
                }}
              />
              <span className={style.percentSymbol}>%</span>
            </div>
          </div>

          <div style={{ marginTop: "19px" }}>
            <Checkbox
              checked={outlockSalesDataStatus}
              onChange={(e) => {
                if (!cardActivityDataStatus && !saleHistoryStatus) {
                  return;
                }
                setoutlockSalesDataStatus(e.target.checked);
                if (e.target.checked) {
                  setdisable((prev) => ({
                    ...prev,
                    outlockSalesDataInput: false,
                  }));
                } else {
                  setoutlockSalesDataInput("");
                  setdisable((prev) => ({
                    ...prev,
                    outlockSalesDataInput: true,
                  }));
                  if (
                    !cardActivityDataStatus ||
                    (!saleHistoryStatus &&
                      !(cardActivityDataStatus && saleHistoryStatus))
                  ) {
                    if (cardActivityDataStatus) {
                      setcardActivityDataInput(100);
                    } else {
                      setsaleHistoryInput(100);
                    }
                  }
                }
              }}
            >
              <Text
                className={
                  outlockSalesDataStatus ? style.active : style.inactive
                }
              >
                {selectedProduct} Sales Data
              </Text>
            </Checkbox>
          </div>
          <div className={style.centerAlignContent}>
            <div
              style={{
                display: "block",
                marginLeft: "24px",
                marginTop: "-10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "14px",
                }}
              >
                <Button
                  className={style.updateButton}
                  type="primary"
                  disabled={disable.outlockSalesDataInput}
                  onClick={() => setshowFileUploadModal("outlockSalesData")}
                >
                  UPLOAD DATA
                </Button>
                <Text className={style.fileNameText}>{savedFiles.T10}</Text>
              </div>
              <div>
                <Link
                  to={
                    selectedProduct == "OutLock"
                      ? "/samples/Outlock_Sales_Data.csv"
                      : selectedProduct == "LoanProtect"
                      ? "/samples/LoanProtect_Sales_Data.csv"
                      : "/samples/Sales_Data.csv"
                  }
                  target="_blank"
                  download
                >
                  <Button
                    style={{ padding: 0 }}
                    disabled={disable.outlockSalesDataInput}
                    type="link"
                  >
                    Download Sample
                  </Button>
                </Link>
              </div>
            </div>
            <div style={{ position: "absolute", right: 0 }}>
              <Input
                className={style.inputBox}
                disabled={disable.outlockSalesDataInput}
                value={outlockSalesDataInput}
                onChange={(e) => setoutlockSalesDataInput(e.target.value)}
                onKeyDown={(evt) => handleKeyDown(evt)}
                onBlur={() => {
                  if (outlockSalesDataInput == "0") {
                    setoutlockSalesDataInput("");
                    setoutlockSalesDataStatus(false);
                    setdisable((prev) => ({
                      ...prev,
                      outlockSalesDataInput: true,
                    }));
                    if (
                      !cardActivityDataStatus ||
                      (!saleHistoryStatus &&
                        !(cardActivityDataStatus && saleHistoryStatus))
                    ) {
                      if (cardActivityDataStatus) {
                        setcardActivityDataInput(100);
                      } else {
                        setsaleHistoryInput(100);
                      }
                    }
                  }
                }}
              />
              <span className={style.percentSymbol}>%</span>
            </div>
          </div>

          <div className={style.separator} />
          <div className={style.totalSection}>
            <span style={{ float: "left" }}>
              <strong>Total</strong>
            </span>
            <span style={{ float: "right" }}>
              <strong
                className={
                  Number(cardActivityDataInput) +
                    Number(saleHistoryInput) +
                    Number(outlockSalesDataInput) !==
                  100
                    ? style.errorText
                    : null
                }
              >
                {Number(cardActivityDataInput) +
                  Number(saleHistoryInput) +
                  Number(outlockSalesDataInput)}
                %
              </strong>
            </span>
          </div>
          <div className={style.errorMessageSection}>
            {!(
              Number(cardActivityDataInput) +
                Number(saleHistoryInput) +
                Number(outlockSalesDataInput) ==
              100
            ) &&
              !showLoading &&
              "Total should be 100"}
          </div>
          <div>
            <Text type="secondary">
              Note: All updates must be in CSV format.
            </Text>
          </div>
        </div>
        <div className={style.buttonSection}>
          <div className={style.buttonContent}>
            <Button
              className={style.button}
              style={{ float: "left" }}
              type="primary"
              onClick={() => history.goBack()}
            >
              BACK
            </Button>
            <Button
              className={style.button}
              style={{ float: "right" }}
              type="primary"
              disabled={
                Number(cardActivityDataInput) +
                  Number(saleHistoryInput) +
                  Number(outlockSalesDataInput) !==
                100
              }
              onClick={() => handleSaveButtonClick()}
            >
              SAVE
            </Button>
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default ProductSetting;
