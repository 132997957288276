import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AuthContextProvider from "contexts/AuthContext";
import SidebarContextProvider from "contexts/SidebarContext";
import Components from "components";
import routes from "route_components";
import "./App.scss";
import AuthorisedPrivateRoute from "api_services/AuthorisedPrivateRoute";

function App() {
  return (
    <AuthContextProvider>
      <Router>
        <SidebarContextProvider>
          <Components.Header />
          <section className="section">
            <div className="container">
              <Switch>
                <Route
                  path="/signin-callback"
                  component={routes.SigninCallback}
                />
                <Route
                  path="/dashboard"
                  component={AuthorisedPrivateRoute()(Components.Dashboard)}
                />
                {/* <Route path="/" component={Components.Overview} /> */}
                <Route
                  path="/user-guide"
                  component={AuthorisedPrivateRoute()(Components.UserGuide)}
                />
                <Route
                  path="/overview"
                  component={AuthorisedPrivateRoute()(Components.Overview)}
                />
                <Route
                  path="/setting"
                  component={AuthorisedPrivateRoute()(
                    Components.ProductSetting
                  )}
                />
                <Route
                  path="/"
                  component={AuthorisedPrivateRoute()(Components.Products)}
                />
              </Switch>
            </div>
          </section>
        </SidebarContextProvider>
        <Components.Footer />
      </Router>
    </AuthContextProvider>
  );
}

export default App;
