import React from "react";
import { Typography } from "antd";
import style from "./overview.module.scss";

const { Text } = Typography;

const BudgetlockOverview = () => (
  <>
    <div className={style.overviewTitleSection}>
      <Text className={style.title}>Underwriting BudgetLock - Overview</Text>
    </div>
    <div className={style.overviewText}>
      <ul>
        <li>
          The engine is built upon roughly 450 million data points covering
          several years of financial transactions across the USA. Those data
          points in turn depend upon the aggregation of tens of billions of
          individual transactions during this period.
        </li>
        <li>
          The USA is divided into roughly 3 million ‘squares’ of roughly 1.5
          miles each. These squares form the basis of the land area upon which
          roughly 16,800 municipalities are located US-wide. Thus, the
          jurisdiction of any municipality may contain only a few of these
          squares (in the case of small towns) all the way to hundreds of such
          squares (in the case of large cities).
        </li>
        <li>
          Changes in the ‘economic health’ of any municipality involve first
          determining the average transaction volume running through the squares
          within this municipality and then observing changes in this average
          over time.
        </li>
        <li>
          If a policyholder buys a BudgetLock, their BudgetLock will trigger
          (i.e. they are owed a predetermined amount of money) if the economic
          health of this municipality drops sharply compared to the nation as a
          whole, the drop is not predictable and its health continues in its
          post-drop state for a predetermined amount of time.
        </li>
        <li>
          This Underwriting Engine explores what the probability of such a
          trigger is, depending on what you set on the next page.
        </li>
      </ul>
    </div>
  </>
);

export default BudgetlockOverview;
