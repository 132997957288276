import React, { useEffect, useState } from "react";
import { Typography, Button, Spin } from "antd";
import { useHistory } from "react-router-dom";
import connectionInstance from "api_services/connection";
import { AuthToken, SelectedProduct } from "api_services/localstorage";
import { SettingOutlined } from "@ant-design/icons";
import Components from "components";
import style from "./products.module.scss";

const { Text } = Typography;

const Products = () => {
  const [hideContent, sethideContent] = useState(true);
  const history = useHistory();

  useEffect(() => {
    async function checkSessionStatus() {
      const response = await connectionInstance
        .get(`${process.env.REACT_APP_API_URL}/probability/request`, {
          params: {
            states: "CA",
            trigger_threshold: -33,
            geo_basis: "square",
          },
        })
        .then((res) => {
          if (res) {
            sethideContent(false);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              AuthToken.removeToken();
              window.location = `${process.env.REACT_APP_AWS_DOMAIN}/logout?client_id=${process.env.REACT_APP_AWS_COGNITO_CLIENT_ID}&response_type=${process.env.REACT_APP_AWS_RESPONSE_TYPE}&redirect_uri=${process.env.REACT_APP_AWS_REDIRECT_URI}`;
            } else {
              sethideContent(false);
            }
          } else {
            sethideContent(false);
          }
        });
    }
    SelectedProduct.removeProduct();
    checkSessionStatus();
  });

  return (
    <div className={style.productsContainer}>
      {!hideContent ? (
        <div className={style.productSection}>
          <Components.ProductTile
            title="BuildLock"
            subtitle="Payment upon weather-caused delays on construction projects"
            status={false}
          />
          <Components.ProductTile
            title="DeductLock"
            subtitle="Payment of deductible upon claim filing"
            status={false}
          />
          <Components.ProductTile
            title="DelayLock"
            subtitle="Payment upon delays on commercial flights"
            status={false}
          />
          <Components.ProductTile
            title="LoanProtect"
            subtitle="Payment of small-commercial loan instalments upon local economic plunge"
            status
          />

          <Components.ProductTile
            title="OutLock"
            subtitle="Payment upon local economic plunge"
            status
          />
          <Components.ProductTile
            title="BudgetLock"
            subtitle="Payment upon economic plunge in city"
            status
            disableSetting
          />
          <Components.ProductTile
            title="PowerLock"
            subtitle="Payment upon significant power outage"
            status={false}
          />
          <Components.ProductTile
            title="RideLock"
            subtitle="Payment upon delayed trip by Uber/Lyft etc."
            status={false}
          />

          <Components.ProductTile
            title="RuleLock"
            subtitle="Payment upon violation of national/state regulations"
            status={false}
          />
          <Components.ProductTile
            title="StormLock"
            subtitle="Payment upon windstorm near property"
            status={false}
          />
          <Components.ProductTile
            title="TransportLock"
            subtitle="Payment upon delay in cargo delivery"
            status={false}
          />
          <Components.ProductTile
            title="WebLock"
            subtitle="Payment upon website failure"
            status={false}
          />
        </div>
      ) : (
        <Spin
          spinning={hideContent}
          style={{ position: "fixed", top: "40%", left: "50%" }}
        />
      )}
    </div>
  );
};

export default Products;
