import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Dropdown, Button, Typography, Menu } from "antd";
import { MCFoooter } from "assets";
import { useAuthContext } from "contexts/AuthContext";
import { DownOutlined } from "@ant-design/icons";
import style from "./_header.module.scss";
import { SelectedProduct } from "api_services/localstorage";

const { Text } = Typography;

const Header = () => {
  const history = useHistory();
  const location = useLocation();
  const userContext = useAuthContext();
  const { user, signout } = userContext;
  const selectedProduct = SelectedProduct.getProduct();

  const redirectToHome = () => {
    history.push("/");
  };

  const handleLogout = () => {
    signout();
    history.push("/");
  };

  const userGuide = () => {
    history.push("/user-guide");
  };

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <Button
          className={style.headerDropdownButton}
          type="link"
          onClick={handleLogout}
        >
          Log Out
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      {user ? (
        // <div className={style.hiddenXS}>
        <header className={style.headerContainer}>
          <div
            className={style.linkSection}
            aria-hidden="true"
            onClick={redirectToHome}
          >
            <img src={MCFoooter} alt="Header logo" />
          </div>

          <div className={style.clientName}>Underwriting Engine</div>

          <div className={style.spacer} />
          {!!location.pathname[1] &&
            location.pathname !== "/setting" &&
            selectedProduct !== "BudgetLock" && (
              <Button
                className={style.userGuide}
                type="link"
                onClick={userGuide}
              >
                User Guide
              </Button>
            )}
          {/* <Link className={style.userGuide} to="/user-guide">
            User Guide
          </Link> */}
          <div className={style.userDetailsSection}>
            <Dropdown overlay={menu} trigger={["click"]}>
              <div className={style.dropdownContainer}>
                <div className={style.username}>
                  <Text type="link" size="small">
                    {user ? user.name : null}
                    {/* Jhon Doe */}
                  </Text>
                  {/* <Text className={style.roleText} type="secondary">
                    {userRole}
                  </Text> */}
                </div>
                <DownOutlined className={style.dropdownIcon} />
              </div>
            </Dropdown>
          </div>
        </header>
      ) : // </div>
      null}
    </div>
  );
};

export default Header;
